"use client";

import { ScheduledEvent } from "@/generated/email_parser.openapi";
import { setEventCode } from "@/lib/userServerActions";
import { useSession } from "next-auth/react";

import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type EventContextType = {
  eventId: string;
  eventData: ScheduledEvent | null;
  eventLoading: boolean;
};

const defaultData: EventContextType = {
  eventId: "",
  eventData: null,
  eventLoading: true,
};

const EventContext = createContext<EventContextType>(defaultData);

export default function EventProvider({ children }: PropsWithChildren) {
  const [eventData, setEventData] = useState<ScheduledEvent | null>(null);
  const [eventLoading, setEventLoading] = useState(true);
  const { status } = useSession();

  const eventId = window.location.pathname.split("/")[2];

  useEffect(() => {
    if (!eventId) {
      return;
    }
    const fetchEventData = async () => {
      try {
        const result = (await fetch("/api/public/scheduled_events", {
          method: "POST",
          body: JSON.stringify({ eventCode: eventId }),
        }).then((res) => res.json())) as ScheduledEvent;
        setEventData(result);
      } catch (error) {
        return;
      } finally {
        setEventLoading(false);
      }
    };
    fetchEventData();
  }, [eventId]);

  useEffect(() => {
    if (status === "unauthenticated" && eventId) setEventCode(eventId);
  }, [status, eventId]);

  return (
    <EventContext.Provider value={{ eventId, eventData, eventLoading }}>
      {children}
    </EventContext.Provider>
  );
}

const useEvent = () => {
  const { eventData, eventId, eventLoading } = useContext(EventContext);
  return { eventData, eventId, eventLoading };
};

export { useEvent };
